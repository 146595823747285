import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserQuery } from '../user/user.query';
import {GenesystokenService} from '../genesystoken.service';
@Injectable({ providedIn: 'root' })
export class requestsService {
    baseApi = environment.baseApi;

    httpheaders = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        )
    };

    fileHeaders = {
        headers: new HttpHeaders({
        })
    }

    imgHeaders = {
        headers: new HttpHeaders({
            'accept': 'image/*'
        })
    }

    audiohttpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/octet-stream',
                'accept': 'application/octet-stream'
            }
        )
    };

    constructor(
        private userQuery: UserQuery,
        private http: HttpClient,private genesys:GenesystokenService) {

    }

    user() {

    }

    query(url, httpheaders = this.httpheaders) {
        return this.http.get<any>(
            `${this.baseApi}${url}`,
            httpheaders
        );
    }

    post(url, params = null, httpheaders = this.httpheaders) {
        return this.http.post<any>(
            `${this.baseApi}${url}`,
            params,
            httpheaders
        );
    }

    put(url, params = null) {
        return this.http.put<any>(
            `${this.baseApi}${url}`,
            params,
            this.fileHeaders
        );
    }

    delete(url, headers = null) {
        const httpheaders = this.httpheaders;
        return this.http.delete<any>(
            `${this.baseApi}${url}`,
            httpheaders
        );
    }

    userPlanDetails() {
        return this.query('/getCognitoUserGroup');
    }

    getChannels() {
        return this.query('/channel/list');
    }

    allServiceList() {
        return this.query('/service/list');
    }

    getService(id) {
        return this.query('/service/get?serviceId=' + id);
    }

    createService(params) {
        return this.post('/service/addUpdate', params);
    }

    updateService(params) {
        return this.post('/service/addUpdate', params);
    }

    removeService(id) {
        return this.delete('/service/delete?serviceId=' + id);
    }

    allScriptsList() {
        return this.query('/script/list');
    }

    allScriptsByChannelId(id) {
        return this.query('/script/listScriptByChannel?channelId=' +id);
    }

    allServiceBychannelId(id) {
        return this.query('/service/listServiceByChannel?channelId='+id);
    }

    getScript(id) {
        return this.query('/script/get?scriptId=' + id);
    }
    createScript(params) {
        return this.post('/script/addUpdate', params);
    }

    updateScript(params) {
        return this.post('/script/addUpdate', params);
    }

    removeScript(id) {
        return this.delete('/script/delete?scriptId=' + id);
    }

    pricingPlans() {
        return this.query('/membership/list');
    }

    allSprints() {
        return this.query('/sprint/listSprint');
    }

    allSprintsByType(type) {
        return this.query('/sprint/listSprintByTestType?testType=' + type);
    }

    createSprint(params) {
        return this.post('/sprint/addUpdateSprint', params);
    }

    sprintById(id) {
        return this.query('/sprint/getSprint?sprintId=' + id);
    }
    // http://demo.onecg.cc:8901/api/v2/onecgtest/sprint/getSprintName/%7Bs[rintid%7D
    getsprintById(id,params) {
        return this.post('/sprint/getAutoPilotHistByDate?sprintId=' + id,params);
    }

    runSpring(id) {
        return this.query('/sprint/runSprint?sprintId=' + id);
    }

    stopSprint(sprint, client) {
        return this.query(`/sprint/terminateSprint?sprintId=${sprint}&clientId=${client}`);
    }
    stoploadSprint(sprint, ) {
        return this.query(`/sprint/terminateLoadTest?loadstartsprintId=${sprint}`);
    }

    removeSpring(id) {
        return this.delete('/sprint/deleteSprint?sprintId=' + id);
    }

    sprintTestHistory(id) {
        return this.query('/autopilotMessage/listMessageData?customerId=' + id);
    }

    sprintStatus(id) {
        return this.query('/sprint/getSprintStatus?clientId=' + id);
    }

    agentProgressSummary(id, clientId) {
        return this.query('/sprint/refreshSprintClientResponse?sprintId=' + id + '&clientId=' + clientId);
    }

    sprintTestHistoryList(id,params) {
        return this.post('/sprint/getAutoPilotHist?sprintId=' + id,params);
    }

    sprintAudioURL(id) {
        return this.baseApi + '/autopilotMessage/getAudio?customerId=' + id;
    }

    sprintAudioDownload(id) {
        return this.query('/autopilotMessage/getAudio?customerId=' + id, this.audiohttpOptions);
    }

    createStripeCheckoutSession(plan) {
        return this.post('/paymentcheckout/savePaymentCard?membershipId=' + plan);
    }

    savePaymentMethodForCustomer(sessiondId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id=' + sessiondId, { id: sessiondId })
    }

    upgradePlan(planId) {
        return this.post('/paymentcheckout/upgardeMembership?membershipId=' + planId, { membershipId: planId });
    }

    getPaymentMethod() {
        return this.post('/paymentcheckout/getPaymentMethodForCustomer');
    }

    cancelMembership() {
        return this.query('/paymentcheckout/cancelMembership');
    }

    membershipHistory() {
        return this.query('/paymentcheckout/membershipHistory');
    }

    getTestTypeForUser() {
        return this.query('/sprint/getTestTypeForGroup');
    }

    getSuccessCriteria() {
        return this.query('/sprint/getSuccessCriteria');
    }

    getSuccessCriteriaReturnValue(id) {
        return this.query('/sprint/getCriteriaValues?successCriteriaId=' + id);
    }

    invoiceReport(params): Observable<any[]> {
        return this.post('/report/getInvoiceDetails', params);
    }

    highLevelReport(params): Observable<any[]> {
        return this.post('/report/getHighLevelDetails', params);
    }
    loadSprintReport(params): Observable<any> {
        return this.post('/sprint/getHighLevelLoadSprintReport', params);
    }
    loadSprintDeatilsReport(loadSprintclientId) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId=' + loadSprintclientId);
    }
    sprintReport(params): Observable<any> {
        return this.post('/report/billing', params);
    }

    uploadProfileImg(params) {
        return this.post('/user/uploadImage', params, this.fileHeaders);
    }

    getUserdetails() {

        if(this.genesys.tokenType=='genesys'){
            var userToken = this.genesys.token;

            console.log(userToken)
             var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://labapi.quicktest.ai/getUserDetails',{headers:headers_object})
        }
        else{
            const userToken = this.userQuery.getToken();
            console.log(userToken)
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://labapi.quicktest.ai/getUserDetails',{headers:headers_object})
        }

       // return this.query('/getUserDetails');
    }
    getProfileImg() {
        if(this.genesys.tokenType=='genesys'){
            var userToken = this.genesys.token;

            console.log(userToken)
             var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://labapi.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
        }else{
            const userToken = this.userQuery.getToken();
            console.log(userToken)
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
            return this.http.get('https://labapi.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
        }
       
       // return this.query('/user/getImage', {headers:headers_object});
    }

    contactus(params): Observable<any> {

        return this.post('/user/contactUs', params);
    }
    getPaymentDetails() {
        return this.query('/paymentcheckout/getCardDetails');
    }
    getupdatePayment() {
        return this.query('/paymentcheckout/updatePayment');
    }
    SaveupdatePayment(paymentId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id='+paymentId,{id:paymentId});
    }
    LoadsprintStatus(id) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id);
    }
    LoadrunSpring(id) {
        return this.query('/sprint/runLoadSprint?sprintId=' + id);
    }

   LoadsprintTestHistoryList(id) {
        return this.query('/sprint/getLoadSprintData?sprintId=' + id);
    }
    LoadsprintTestHistoryListbyDate(id,params) {
        return this.post('/sprint/getLoadSprintDataByDate?sprintId=' + id,params);
    }

    loadStatussprintById(id) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id);
    }

    loadStatusssprintDetails(id) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId='+id);
    }
    loadStatusssprintMessage(id) {
        return this.query('/autopilotMessage/listMessageData?customerId='+id);
    }

    getDashboardSprintData() {
        return this.query('/dashboardSprint/dashboardScheduleSprint');
    }

    getCountryPhoneNo(coundtryCode) {
        return this.query('/icd/listNumbers?countryCode='+coundtryCode);
    }

    addPhoneNumber(params) {
        return this.post('/fromDetails/addFromNumber', params);
    }

    getListNumber() {
        return this.query('/fromDetails/listNumbers');
    }
    getActiveNumber() {
        return this.query('/whatsappSettings/getActiveWhatsAppDetails');
    }

    deletePhonenumber(phoneNumber) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }),
            body: {
                number:phoneNumber
            },
          };

        var url='/fromDetails/releaseNumber';
        return this.http.delete<any>(
            `${this.baseApi}${url}`,options
        );
        // return this.ht
        // return this.delete('/fromDetails/releaseNumber?number=' + phoneNumber);
    }
    getChatSubChannels(channelId) {
        return this.query('/channel/subchannels?channelId=' +channelId);
    }

    cloudchatRefreshSprintExecution(id, clientId) {
        return this.query('/sprint/refreshSprintExecution?sprintId=' + id + '&clientId=' + clientId);
    }
    addWhatsappNumber(params): Observable<any> {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);
        return this.http.post('https://labapi.quicktest.ai/whatsappSettings/addWhatsappNumber', params,{headers:headers_object});
    }
    getWhatsAppDetails() {
        return this.query('/whatsappSettings/getWhatsAppDetails');
    }
    
    terminateWhatsappAddNumber(id) {
        return this.query('/whatsappSettings/terminateWhatsappAddNumber?id='+id);
    }
    getQRCode(id) {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType);

        return this.http.get('https://labapi.quicktest.ai/whatsappSettings/getqrCode?id='+id, {headers:headers_object,responseType:'blob'});
    }
    WhatsApptStatus(id) {
        return this.query('/whatsappSettings/getqrstatus?id=' + id);
    }

}