<div class="verify-user-container">
    <div class="brand-name">
        <a routerLink="/"><img src="/assets/brand-logo.png"></a>
    </div>
    <div class="body-wrapper">
        <div class="card">
            <div class="banner">
                <img src="assets/illustrations/verify-user.png">
            </div>
            <app-heading [title]="'We\'ve sent you Verification Code'" [size]="20"></app-heading>
            <div class="info"> Verification Code has been sent on your Email address. Please enter the code to reset your password</div>
            <div class="form" style="width: 30%;
            margin: auto;">
                <form [formGroup]="verifyUser" (submit)="verify($event)">
                    <app-form-input [label]="'Verification code'">
                        <input type="text" formControlName="code">
                    </app-form-input>
                    <div class="mrtop">
                        <app-form-input [label]="'New Password'">
                            <input type="password" formControlName="password">
                        </app-form-input>
                    </div>
                    <div *ngIf = "verifyUser.get('password').hasError('minlength')" class = "alert-danger">
                        <span>Minimo 8 caratteri</span>
                      </div>
                      <div *ngIf = "verifyUser.get('password').hasError('pattern')" class = "alert-danger">
                        <span>Minimo 8 caratteri, con almeno 1 numero, una lettera maiuscola e un carattere speciale</span>
                      </div>
                    <div class="btn">
                        <app-button>Verify</app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>