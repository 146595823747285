<div class="login-container">
    <div class="col s-100 panel-left">
        <div class="banner">
            <img class="bg" src="assets/illustrations/signup-banner.png">
        </div>
        <div class="jumbotron">
            <div class="box-wrapper">
                <div class="box">
                    <div class="heading">Benefits of QuickTest</div>
                    <div class="s-line"></div>
                    <div class="list">
                        <div class="item"><img src="/assets/icons/check-round.svg">Review data on Dashboard</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Perform Load Testing</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Scheduled Test Performance</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Automatic Testing</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">IVR Testing</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="brand">
            <img src="assets/brand-logo-light.png">
        </div>
    </div>
    <div class="col s-100 panel-right">
        <div class="form-wrapper animate fade-in">
            <app-heading [title]="'Register'"></app-heading>
            <form class="form" (submit)="signup($event)" [formGroup]="signUpForm">
                <app-alert *ngIf="showError" [type]="'danger'">
                    <div content>{{showError}}</div>
                </app-alert>
                <app-alert *ngIf="showSuccess">
                    <div content>Account successfully created</div>
                </app-alert>
                <div class="row">
                    <div class="split">
                        <app-form-input [label]="'First Name'" [error]="signUpForm.controls.firstName">
                            <input type="text" formControlName="firstName">
                        </app-form-input>
                    </div>
                    <div class="split">
                        <app-form-input [label]="'Last Name'" [error]="signUpForm.controls.lastName">
                            <input type="text" formControlName="lastName">
                        </app-form-input>
                    </div>
                </div>
                <app-form-input [label]="'Email'" [error]="signUpForm.controls.email">
                    <input type="email" formControlName="email">
                </app-form-input>
                <!-- <app-form-input [label]="'Phone'" [error]="signUpForm.controls.phone">
                    <input type="text" formControlName="phone">
                </app-form-input> -->
                <app-form-input class="input" [type]="'phone'" [label]="'Phone'" [error]="signUpForm.controls.phone"
                    (change)="setPhone($event)">
                </app-form-input>
                <app-form-input [label]="'Password'" [error]="signUpForm.controls.password">
                    <input type="password" formControlName="password">
                </app-form-input>
                <div *ngIf = "signUpForm.get('password').hasError('minlength')" class = "alert-danger">
                    <span>Minimo 8 caratteri</span>
                  </div>
                  <div *ngIf = "signUpForm.get('password').hasError('pattern')" class = "alert-danger">
                    <span>Minimo 8 caratteri, con almeno 1 numero, una lettera maiuscola e un carattere speciale</span>
                  </div>
                <app-form-input [label]="'Confirm Password'" [error]="signUpForm.controls.confirmPassword">
                    <input type="password" formControlName="confirmPassword">
                </app-form-input>
                <div class="terms-check">
                    <app-form-checkbox [value]="signUpForm.value.acceptTerms" (change)="setTerms($event)">
                    </app-form-checkbox>
                    <div class="plabel">I accept the <a href="https://quicktest.ai/terms-and-conditions/"
                            target="_BLANK">Terms of
                            Service</a> and have read the <a href="https://quicktest.ai/privacy-policy-2/"
                            target="_BLANK">Privacy
                            Statement</a>.
                    </div>
                </div>
                <button class="hide">signup</button>
                <div class="btn">
                    <app-button>Register</app-button>
                    <div class="login-link">
                        Already have an account? <a routerLink="/login">Sign In</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>